import { ILoggerEventArg } from '../typings/logger-event';
import { AdlCmpStub } from './api';

export const trackEvent = (event: ILoggerEventArg) => {
  const data = JSON.stringify(event);
  const { hash } = AdlCmpStub.getScriptAttr();
  const endpoint = `event${hash ? `?hash=${hash}` : ''}`;

  fetch(`${KEEPER_URL}/${endpoint}`, {
    method: 'POST',
    referrerPolicy: 'unsafe-url',
    body: data
  });
};

export const trackError = async (error: Error): Promise<void> => {
  const payload = {
    exception: {
      values: [
        {
          type: error.name,
          value: error.message,
          stacktrace: {
            frames:
              error.stack && typeof error.stack === 'string'
                ? error.stack.split('\n').map((line) => ({ filename: line.trim() }))
                : null
          }
        }
      ]
    },
    tags: {
      environment: ENV
    }
  };

  await fetch(SENTRY_DSN, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-Sentry-Auth': `Sentry sentry_version=7, sentry_key=${SENTRY_PUBLIC_KEY}`
    },
    body: JSON.stringify(payload)
  });
};
