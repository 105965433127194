export const getHostFromUrl = (url: string) => {
  const a = document.createElement('a');

  if (url) {
    a.href = url;

    return a.hostname;
  }

  return '';
};

export const getTopDomain = (domain: string) => {
  const hostname = getHostFromUrl(domain);
  const result = hostname.match(/([\w]+).([\w]{2,4}\.)?([\w]+)$/);

  return (result && result[0]) || domain;
};

export const parseQuery = (queryString = '') => {
  const query = {};
  const pairs = (queryString[0] === '?' ? queryString.slice(1) : queryString).split('&');

  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split('=');

    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '');
  }

  return query;
};

export const getParamsFromUrl = (url: string) => {
  const a = document.createElement('a');

  if (url) {
    a.href = url;

    return parseQuery(a.search);
  }

  return {};
};

export const getDomainFromCookieHost = (host: string) => {
  if (host[0] === '.') {
    return host.substring(1);
  } else if (host[0] === '*') {
    return host.substring(2);
  }

  return host;
};

export const isRestrictedInlinePresent = (text: string): boolean => {
  return /admixerML|googletag\.|\.cookie[^=]?=/im.test(text);
};

export const parseAllUrls = (text: string): string[] | null => {
  return text.match(
    // eslint-disable-next-line max-len
    /(?:(?:https?|ftp|file):|\/\/|www\.|ftp\.)(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[-A-Z0-9+&@#\/%=~_|$?!:,.])*(?:\([-A-Z0-9+&@#\/%=~_|$?!:,.]*\)|[A-Z0-9+&@#\/%=~_|$])/gim
  );
};
