window.dataLayer = window.dataLayer || [];

function gtag(..._args) {
  // eslint-disable-next-line prefer-rest-params
  window.dataLayer.push(arguments);
}

export const initDefaultParams = () => {
  if (typeof window['__tcfapi'] === 'function') {
    window['gtag_enable_tcf_support'] = true;
  }

  gtag('consent', 'default', {
    ad_personalization: 'denied',
    ad_user_data: 'denied',
    ad_storage: 'denied',
    analytics_storage: 'denied',
    functionality_storage: 'denied',
    personalization_storage: 'denied',
    security_storage: 'granted',
    wait_for_update: 1000
  });

  gtag('set', 'ads_data_redaction', true);
};

export const allowAllGoogleConsent = () => {
  gtag('consent', 'update', {
    ad_personalization: 'granted',
    ad_user_data: 'granted',
    ad_storage: 'granted',
    analytics_storage: 'granted',
    functionality_storage: 'granted',
    personalization_storage: 'granted',
    security_storage: 'granted'
  });
};
