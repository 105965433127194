import { EVENT_LIST } from '../constants/EVENT_LIST';
import { STORAGE_KEY } from '../constants/STORAGE_KEY';
import { TEMPLATE_TYPE } from '../constants/TEMPLATE_TYPE';
import { ICmpStubApi, IStubConfig } from '../typings/api';
import { arrayIntersect } from '../utils/array';
import { checkIsIamFirst, checkTestMode, getScriptUrl, isScriptAvailable } from '../utils/base';
import { scriptAttr } from '../utils/consent-config';
import { getCookie, setCookie } from '../utils/cookie';
import * as gtag from '../utils/gtag';
import * as mutationObserver from './mutation-observer';
import { CmpStubApi } from './stub-api';

export let AdlCmpStub: ICmpStubApi;
export let stubConfig: IStubConfig;

export const initApi = async () => {
  if (isScriptAvailable()) {
    const { hash: scriptHashId } = scriptAttr;
    // const hashFromCookie = getCookie(STORAGE_KEY.ADL_CMP_HASH_ID);

    checkIsIamFirst();

    // run mutationObserver
    mutationObserver.init();

    try {
      // Load stub config data
      stubConfig = await getStubConfig(scriptHashId);
    } catch (e) {
      console.error('CMP Error: ' + e.message);
    }

    const scriptConfig = getScriptConfigByRegion(stubConfig);

    if (!checkTestMode(scriptConfig)) {
      console.warn('TEST MODE: CMP stub is not loaded');

      return mutationObserver.recoveryMutedElements(true, true);
    } else {
      mutationObserver.recoveryMutedElements(false);
    }

    // if (stubConfig.show_on_subdomains && scriptHashId && (!hashFromCookie || hashFromCookie != scriptHashId)) {
    //   setCookie({ key: STORAGE_KEY.ADL_CMP_HASH_ID, value: scriptHashId, applyOnSubdomains: true });
    // }

    // set strict config
    AdlCmpStub = new CmpStubApi({ scriptConfig });
    AdlCmpStub.filterData = mutationObserver.filterData;

    window['AdlCmpStub'] = AdlCmpStub;

    // set gtag default config
    gtag.initDefaultParams();

    window.dispatchEvent(new CustomEvent(EVENT_LIST.CMP_STUB_LOADED));

    // Load script from CDN
    appendCdnScript(scriptHashId);
  }
};

const appendCdnScript = async (hashId: string, needCheckVersion = true) => {
  const scriptEl = document.createElement('script');
  const currentScript = document.getElementsByTagName('script')[0];
  const { lang, hash } = AdlCmpStub.getScriptAttr();
  const scriptQueryParams = {};

  if (lang) {
    scriptEl.setAttribute('data-lang', lang);
  }

  if (stubConfig && stubConfig.last_update_config_date) {
    scriptQueryParams['t'] = stubConfig.last_update_config_date;
  }

  if (!needCheckVersion) {
    scriptQueryParams['t'] = `${scriptQueryParams['t'] ? scriptQueryParams['t'] + '_' : ''}${new Date().getTime()}`;
  }

  const scriptQueryParamsStr = new URLSearchParams(scriptQueryParams).toString();

  scriptEl.async = true;
  scriptEl.setAttribute('data-adl-consent', 'ignore');
  scriptEl.setAttribute('data-hash', hash);
  scriptEl.src = `${getScriptUrl(hashId)}${scriptQueryParamsStr ? '?' + scriptQueryParamsStr : ''}`;
  scriptEl.onload = () => {
    if (window['AdlCmp']) {
      window['AdlCmp'].addEventListener(EVENT_LIST.CMP_SCRIPT_INITIALIZED, () => {
        AdlCmpStub.isCMPActive = true;
        // recover blocked elements according to loaded site/app config
        mutationObserver.recoveryMutedElements(false);
      });

      // compare script version to current version
      if (
        stubConfig &&
        needCheckVersion &&
        window['AdlCmp']['scriptVersion'] !== stubConfig.script_version?.replace(/[^\d\.]/g, '')
      ) {
        window['AdlCmp'].destroy();

        appendCdnScript(hashId, false);
      } else {
        // run cmp
        window['AdlCmp'].run();
      }
    } else {
      AdlCmpStub.stop();
    }
  };
  scriptEl.onerror = () => {
    AdlCmpStub.stop();
  };

  currentScript.after(scriptEl);
};

const getStubConfig = (hashId: string): Promise<IStubConfig> => {
  return fetch(`${DETERMINATOR_URL}/config/stub?hash=${hashId}`).then((res) => res.json());
};

const getScriptConfigByRegion = (stubConfig?: IStubConfig) => {
  let allWorldConfigId: number | null = null;
  let configId: number | null = null;
  const templateTypeIdRel: Record<number, TEMPLATE_TYPE> = {};

  if (stubConfig && stubConfig.config) {
    const clientCountry = stubConfig.country_code;
    const clientState = stubConfig.subdivision_codes;

    stubConfig.config
      .sort((a, b) => {
        if ((a.regions === null || a.states !== null) && (b.regions !== null || b.states === null)) {
          return -1;
        }

        if ((a.regions !== null || a.states === null) && (b.regions === null || b.states !== null)) {
          return 1;
        }

        return 0;
      })
      .some((item) => {
        templateTypeIdRel[item.id] = item.template_type;

        if (!allWorldConfigId && item.is_whole_world) {
          allWorldConfigId = item.id;
        }

        if (Array.isArray(clientState) && arrayIntersect(item.states || [], clientState).length) {
          configId = item.id;

          return true;
        } else if ((item.countries || []).includes(clientCountry)) {
          configId = item.id;

          return true;
        }

        return false;
      });
  }

  if (!configId && allWorldConfigId) {
    configId = allWorldConfigId;
  }

  return {
    templateType: configId ? templateTypeIdRel[configId] : null,
    id: configId,
    isTestModeActive: stubConfig?.is_test_mode_active || false,
    isLoaded: stubConfig && stubConfig['status'],
    showOnSubdomains: stubConfig?.show_on_subdomains || false
  };
};
