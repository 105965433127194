import { initApi } from './services/api';
import { trackError } from './services/event-logger';

initApi().catch((error) => {
  if (error instanceof Error) {
    trackError(error);
  }

  console.warn(error, 'CMP stub is not loaded');
});
